<script setup lang="ts">
import type { NuxtError } from "#app";

useSeoMeta({
  title: "Page not found",
  description: "The page you are looking for does not exist.",
});

defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true,
  },
});

useHead({
  htmlAttrs: {
    lang: "ro",
  },
});
</script>

<template>
  <div>
    <UMain>
      <UContainer>
        <UPage>
          <UPageError :error="error" />
        </UPage>
      </UContainer>
    </UMain>

    <UNotifications />
  </div>
</template>
