<script setup lang="ts">
const store = useStore();
const colorMode = useColorMode();
const color = computed(() =>
  colorMode.value === "dark" ? "#18181b" : "white"
);

if (process.client) {
  store.init();
}

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "ro",
  },
});

const title = "Uimire Company";
const description = "Uimire Company";

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
  // ogImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  // twitterImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  twitterCard: "summary_large_image",
});
</script>

<template>
  <VitePwaManifest />

  <NuxtLayout />

  <UModals />
</template>
