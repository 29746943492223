export const useStore = defineStore({
  id: "main",
  persist: {
    storage: persistedState.localStorage,
    paths: [
      "token",
      "validated",
      "user",
    ],
  },
  state: () => {
    return {
      token: "",
      validated: false,
      user: null,
    };
  },
  getters: {},
  actions: {
    init(): void {
      if (process.client) {
        // validate token
        if (this.token) {
          useApi("GET", "/v1/company/auth/validate").then((res: any) => {
            if (res) {
              this.login(res as any);

              const route = useRoute();
              if (route.path === "/auth") {
                navigateTo("/");
              }
            }
          });
        }
      }
    },
    login(data: any) {
      if (data.access_token) {
        this.token = data.access_token;
      }
      this.user = data.data;
      this.validated = true;
    },
    setToken(token: string): void {
      this.token = token;
      window.location.href = "/dashboard";
    },
    validate(to: any) {
      if (!to.path.includes("logout")) {
        useApi("GET", "/v1/company/auth/validate").then((res: any) => {
          if (res) {
            this.validated = true;
            this.login({
              data: res,
            });
          } else {
            this.logout();
          }
          // if (to) {
          //   navigateTo(to)
          // }
        });
      }
    },
    logout(): void {
      this.token = "";
      this.user = null;
      this.validated = false;

      // navigateTo doesn't fucking work sometimes
      if (process.client) {
        window.location.href = "/";
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
