export default defineAppConfig({
  ui: {
    primary: 'yellow',
    gray: 'zinc',
    tooltip: {
      default: {
        openDelay: 500,
      },
    },
    page: {
      card: {
        wrapper:
          "cursor-pointer hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-400 hover:bg-gray-100/50 dark:hover:bg-gray-800/50",
      },
    },
    dashboard: {
      modal: {
        height: 'min-h-dvh h-full md:h-fit md:min-h-min',
        width: 'sm:max-w-md'
      }
    }
  },
});
