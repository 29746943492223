import { default as allS7qBKlq3aaMeta } from "/vercel/path0/pages/accounts/all.vue?macro=true";
import { default as _91token_935vv2je8KPbMeta } from "/vercel/path0/pages/auth/[token].vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as logout5qQMyX1mvLMeta } from "/vercel/path0/pages/auth/logout.vue?macro=true";
import { default as all1XQZQ6QQBFMeta } from "/vercel/path0/pages/clients/all.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93moV6CvcUIdMeta } from "/vercel/path0/pages/invoices/[id].vue?macro=true";
import { default as allWb3xF9qXTOMeta } from "/vercel/path0/pages/invoices/all.vue?macro=true";
export default [
  {
    name: "accounts-all",
    path: "/accounts/all",
    component: () => import("/vercel/path0/pages/accounts/all.vue").then(m => m.default || m)
  },
  {
    name: "auth-token",
    path: "/auth/:token()",
    meta: _91token_935vv2je8KPbMeta || {},
    component: () => import("/vercel/path0/pages/auth/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexkppHktB81xMeta || {},
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logout5qQMyX1mvLMeta || {},
    component: () => import("/vercel/path0/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "clients-all",
    path: "/clients/all",
    component: () => import("/vercel/path0/pages/clients/all.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_93moV6CvcUIdMeta || {},
    component: () => import("/vercel/path0/pages/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-all",
    path: "/invoices/all",
    component: () => import("/vercel/path0/pages/invoices/all.vue").then(m => m.default || m)
  }
]