import { useStore } from "@/store";

// const baseUrl = process.dev
//   ? "http://192.168.100.233:8000"
//   : "https://api.uimire.com";

const baseUrl = "https://api.uimire.com";

export default function useApi(
  method: string,
  url: string,
  data: any = null,
): any {
  const store = useStore();

  return $fetch(baseUrl + url, {
    method: method as any,
    body: data,
    headers: {
      Authorization: "Bearer " + store.token,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((r: any) => {
      return r;
    })
    .catch((e: any) => {
      if (!e || !e.response || !e.response.status) {
        return false;
      } else if (e.response.status === 422) {
        console.log("422: Invalid data");
      }

      return false;
    });
}
